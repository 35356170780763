<template>
  <md-card>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-100">
          <ul>
            <li>
              {{ $t("device.slot.slotDetail.identifier") }}: {{ deviceSlot.id }}
            </li>
            <li>
              {{ $t("device.slot.slotDetail.alias") }}: {{ deviceSlot.alias }}
            </li>
            <li>
              {{ $t("device.slot.slotDetail.parameterName") }}:
              {{ deviceSlot.parameterName }}
            </li>
          </ul>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "slot-info",
  props: {
    deviceSlot: {
      type: Object,
    },
  },
};
</script>