<template>
  <div class="row">
    <div class="col-12" :class="{ 'col-md-12': !showMap, 'col-md-6': showMap }">
      <slot-info :deviceSlot="slot" />
    </div>
  </div>
</template>

<script>
import SlotInfo from "../../components/Slots/SlotInfo";
export default {
  components: {
    SlotInfo,
  },
  data: () => ({
    slot: {},
    showMap: false,
  }),
  methods: {
    getSlot() {
      this.$store
        .dispatch("slot/getSlot", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          this.slot = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSlot();
  },
};
</script>